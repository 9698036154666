.site-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 500;
	width: 100%;
	background-color: #fff;
	transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);

	.cont-header {
		display: flex;
		align-items: stretch;
		justify-content: space-between;
		position: relative;
		height: 100px;
		transition: height 0.6s cubic-bezier(0.22, 1, 0.36, 1);

		.nav-toggle {
			position: absolute;
			top: 0;
			left: 0;
			transform: rotate(-90deg) translateY(-250%);
			transform-origin: top right;
			button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100px;
				height: 40px;
				border: 0;
				background: #fff;
				transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
				span {
					font-family: $fontSecondaryLight;
					font-size: 12px;
					letter-spacing: 2px;
					text-transform: uppercase;
					color: $fontcolor;
					margin-left: 0.5em;
				}
			}
		}
		.extra {
			position: absolute;
			top: 0;
			right: 0;
			transform-origin: top left;
			transform: rotate(90deg) translateY(-250%);
			a {
				display: grid;
				place-items: center;
				width: 100px;
				height: 40px;
				border: 0;
				background: #fff;
				font-family: $fontSecondaryLight;
				font-size: 12px;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: $fontcolor;
				text-decoration: none;
				line-height: 1em;
				transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
			}
		}
		.logo {
			max-width: 120px;
			margin: auto;
			img {
				width: 100%;
				height: auto;
			}
		}
	}

	&.is-active {
		background-color: $gray;
		.cont-header {
			.nav-toggle {
				button {
					background: $gray;
				}
			}
			.extra {
				a {
					background: $gray;
				}
			}
		}
	}

	@media #{$break-sm} {
		.cont-header {
			.logo {
				max-width: 180px;
			}
		}
	}
}

.menu-overlay {
	position: fixed;
	top: 100px;
	left: 0;
	z-index: 500;
	width: 100%;
	height: calc(100% - 100px);
	visibility: hidden;
	opacity: 0;

	&.is-active {
		visibility: visible;
		opacity: 1;
	}

	.menu-bg {
		position: absolute;
		top: 0;
		left: 0;
		background-color: $gray;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.cc {
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		width: 1059px;
		height: auto;
		transform: translateY(-50%);
	}

	.mo-inner {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		border-top: 1px solid $gray2;
		display: grid;
		place-items: center;
		.menu {
			nav {
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					li {
						margin-bottom: 1.25em;
						a {
							display: block;
							font-family: $fontPrimary;
							font-size: 48px;
							font-size: clamp(42px, 4vw, 48px);
							line-height: 1em;
							letter-spacing: 0.05em;
							text-transform: uppercase;
							color: $fontcolor;
							text-decoration: none;
							transition: all 0.6s cubic-bezier(0.22, 1, 0.36, 1);
							&:hover,
							&:focus {
								color: $green;
								transform: translateX(0.25em);
							}
						}
						.heading {
							font-size: 24px;
							font-family: $fontSecondaryLight;
							margin-bottom: 0.5em;
						}
						ul {
							margin-bottom: 1.75em;
							li {
								margin-bottom: 0.8em;
								a {
									display: block;
									position: relative;
									span {
										font-size: 16px;
										font-family: $fontPrimary;
										position: absolute;
										top: 0;
										left: 0;
										line-height: 1em;
										display: block;
										transform-origin: 0 0;
										transform: rotate(90deg) translateX(0.25em)
											translateY(-1em);
									}
									div {
										padding-left: 0.75em;
										em {
											font-style: normal;
											font-size: 13px;
											font-family: $fontPrimary;
											display: block;
											margin: 0 0 1em 0;
											padding: 0.15em 0 0 0;
											line-height: 1em;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.side {
			display: none;
		}
	}

	@media #{$break-sm} {
		.mo-inner {
			.container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}
			.menu {
				width: 50%;
			}
			.side {
				display: block;
				width: 50%;
				.inner {
					position: relative;
					.loc {
						position: relative;
						margin-bottom: 2em;
						.heading {
							display: inline-block;
							position: absolute;
							top: 0;
							left: 0;
							transform-origin: bottom left;
							transform: rotate(90deg) translateX(-1em);
							font-family: $fontPrimary;
							font-size: 14px;
							text-transform: uppercase;
							letter-spacing: 0.25em;
						}
						.txt {
							padding-left: 2.3em;
							a {
								color: $fontcolor;
								&:hover {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}

	@media #{$break-md} {
		.mo-inner {
			.side {
				position: relative;
				width: 45%;
				&::before {
					content: "";
					display: block;
					width: 100px;
					height: 5px;
					background-color: $green;
					position: absolute;
					top: 0;
					left: 0;
					transform: translateX(-115%) translateY(0.5em);
				}
			}
		}
	}

	@media #{$break-lg} {
		.mo-inner {
			.side {
				width: 40%;
			}
		}
	}
}

.site-footer {
	background-color: $fontcolor;
	padding: 3em 0;
	.logo {
		max-width: 120px;
		margin: auto;
		margin-bottom: 1em;
		img {
			width: 100%;
			height: auto;
		}
	}
	.side {
		width: 100%;
		text-align: center;
		a {
			color: #fff;
			font-family: $fontPrimary;
			text-decoration: none;
			font-size: 13px;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			&:hover {
				color: $green;
			}
		}
	}

	@media #{$break-md} {
		position: relative;

		&::before {
			content: "";
			display: block;
			width: 1px;
			height: 100%;
			background-color: #fff;
			opacity: 0.2;
			position: absolute;
			top: 0;
			left: 50%;
		}

		.container {
			display: flex;
			flex-direction: row;
			align-items: center;
			.logo {
				max-width: 180px;
			}
			.side {
				text-align: right;
			}
		}
	}
}

/* .whatsapp-globe {
	position: fixed;
	bottom: 15px;
	right: 15px;
	z-index: 200;
	a {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0;
		width: 50px;
		height: 50px;
		background: #41ed7b;
		border-radius: 100px;
		color: $fontcolor;
		transition: all 0.2s ease-out;
		span {
			display: none;
		}
		&:hover,
		&:focus {
			background: $fontcolor;
			color: #41ed7b;
		}
	}
	@media #{$break-sm} {
		a {
			display: inline-flex;
			align-items: center;
			padding: 1em 1.3em 0.9em;

			line-height: 1em;
			text-decoration: none;
			font-size: 13px;
			width: inherit;
			height: inherit;
			span {
				margin-left: 10px;
				position: relative;
				display: block;
			}
		}
	}
} */
