// Custom.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "node_modules/bootstrap/scss/maps";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "node_modules/bootstrap/scss/utilities";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/containers";
@import "node_modules/bootstrap/scss/helpers";
@import "node_modules/bootstrap/scss/forms";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/close";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "node_modules/bootstrap/scss/utilities/api";

@import "node_modules/aos/dist/aos";
@import "_fonts";
@import "_variables";
@import "_general";
@import "_header-footer";
