@font-face {
	font-family: "AT Surt";
	src: url("../fonts/ATSurt-Light.woff2") format("woff2"),
		url("../fonts/ATSurt-Light.woff") format("woff"),
		url("../fonts/ATSurt-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Larsseit Light";
	src: url("../fonts/Larsseit-Light.woff2") format("woff2"),
		url("../fonts/Larsseit-Light.woff") format("woff"),
		url("../fonts/Larsseit-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Larsseit Bold";
	src: url("../fonts/Larsseit-Bold.woff2") format("woff2"),
		url("../fonts/Larsseit-Bold.woff") format("woff"),
		url("../fonts/Larsseit-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

/*
@font-face {
	font-family: "Larsseit ExtraBold";
	src: url("../fonts/Larsseit-ExtraBold.woff2") format("woff2"),
		url("../fonts/Larsseit-ExtraBold.woff") format("woff"),
		url("../fonts/Larsseit-ExtraBold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Larsseit Medium";
	src: url("../fonts/Larsseit-Medium.woff2") format("woff2"),
		url("../fonts/Larsseit-Medium.woff") format("woff"),
		url("../fonts/Larsseit-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Larsseit Thin";
	src: url("../fonts/Larsseit-Thin.woff2") format("woff2"),
		url("../fonts/Larsseit-Thin.woff") format("woff"),
		url("../fonts/Larsseit-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Larsseit";
	src: url("../fonts/Larsseit.woff2") format("woff2"),
		url("../fonts/Larsseit.woff") format("woff"),
		url("../fonts/Larsseit.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
*/
