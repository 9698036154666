html.lenis {
	height: auto;
}

.lenis.lenis-smooth {
	scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
	overscroll-behavior: contain;
}

.lenis.lenis-stopped {
	overflow: hidden;
}

.lenis.lenis-scrolling iframe {
	pointer-events: none;
}

body {
	font-family: $fontSecondaryLight;
	font-size: 20px;
	overflow-x: hidden;
	letter-spacing: 0.015em;
	&.overhidden {
		overflow: hidden;
	}
}

p {
	line-height: 1.6em;
	margin-bottom: 1.2em;
	strong {
		font-family: $fontSecondaryBold;
	}
}

.btn {
	font-family: $fontPrimary;
	font-size: 13px;
	text-decoration: none;
	&.btn-solid {
		background-color: $green;
		color: #fff;
		border-radius: 0;
		padding: 1em 1.35em;
		display: inline-flex;
		align-items: center;
		span {
			line-height: 1em;
			text-transform: uppercase;
			letter-spacing: 0.085em;
		}
		&:has(svg) {
			span {
				display: inline-block;
				margin-left: 1em;
			}
		}
		&:hover,
		&:focus {
			background-color: mix($green, black, 50%);
			color: mix($green, white, 50%);
		}
		&.blanco {
			background-color: #fff;
			color: $green;
			span {
				color: $green;
			}
			&:hover,
			&:focus {
				background-color: mix($green, black, 50%);
				color: mix($green, white, 50%);
			}
		}
	}
}

.cont-sup {
	position: relative;
	width: calc(100% - 80px);
	margin: auto;
	z-index: 20;
}

.cont-lines {
	position: relative;
	width: calc(100% - 80px);
	margin: auto;
	z-index: 1;
}

.cont {
	position: absolute;
	top: 0%;
	left: 50%;
	height: 100%;
	z-index: 1;
	width: calc(100% - 40px);
	user-select: none;
	border-left: 1px solid $gray2;
	border-right: 1px solid $gray2;
	transform: translateX(-50%);
	&.lines {
		.line {
			display: none;
			position: absolute;
			top: 0;
			width: 1px;
			height: 100%;
			background-color: $gray2;
			&:nth-child(1) {
				left: 25%;
			}
			&:nth-child(2) {
				left: 50%;
			}
			&:nth-child(3) {
				left: 75%;
			}
		}
	}
	@media #{$break-sm} {
		&.lines {
			.line {
				display: block;
			}
		}
	}
}

.heading-s1 {
	.h-tag {
		color: $green;
		font-family: $fontSecondaryBold;
		font-size: 20px;
	}
	.h-title {
		font-family: $fontPrimary;
		font-size: 56px;
		font-size: clamp(42px, 7vw, 56px);
		line-height: 1.2em;
		text-transform: uppercase;
		color: $fontcolor;
		em {
			display: inline-block;
			background-color: $green;
			font-style: normal;
		}
		&.large {
			font-size: 36px;
			font-size: clamp(24px, 3vw, 56px);
			line-height: 1.35em;
		}
	}
}

.heading-s2 {
	.h-tag {
		color: $green;
		font-family: $fontSecondaryBold;
		font-size: 20px;
	}
	.h-title {
		font-family: $fontPrimary;
		font-size: 24px;
		line-height: 1.2em;
		text-transform: uppercase;
		color: $fontcolor;
		em {
			display: inline-block;
			background-color: $green;
			font-style: normal;
		}
		&.large {
			font-size: 36px;
			font-size: clamp(24px, 3vw, 56px);
			line-height: 1.35em;
		}
	}
}
